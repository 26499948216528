import PartnerShippingMethodShip from '../../resource_models/partner_shipping_method_ship'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new PartnerShippingMethodShip(state.entities[id])
  )
}

export const find = (state) => (id) => {
  return new PartnerShippingMethodShip(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
