import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'partner_shipping_method_ships',
  attributes: [
    'id',
    'partner_id',
    'shipping_method_id',
    'name',
    'rate_type',
    'rate_rule',
    'is_available'
  ],
  editableAttributes: [
    'partner_id',
    'shipping_method_id',
    'rate_rule',
    'is_available'
  ]
}

export default class PartnerShippingMethodShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  save() {
    if (this.isNewRecord()) {
      return axios.post(this.apiBasePath(), this.requestBody())
    }
    return axios.put(`${this.apiBasePath()}/${this.id}`, this.requestBody())
  }

  // extra methods or helpers here...
}
